/* global IS_TEST */
import React from 'react';
import PropTypes from 'prop-types';

export default class QuerySize extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    };

    state = {
        width: null,
        height: null,
    };

    handleSize = (el)=> {
        this.setState({
            width: el ? el.clientWidth : null,
            height: el ? el.clientHeight : null,
        });
    };

    render() {
        const {width, height} = this.state;
        return (<div ref={this.handleSize} style={{width: '100%', height: '100%'}}>
            {(width && height) || IS_TEST
                ? React.cloneElement(this.props.children, {width, height})
                : null}
        </div>);

    }
}
