import find from 'lodash/find';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import settings from 'airborne/settings';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import gettext from 'airborne/gettext';

import MultiSearchContext from './MultiSearchContext';
import SearchFilters from './SearchFilters';

import Button from 'midoffice/components/Button';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import {MapContainer, useMapEvents} from 'react-leaflet';
import {GoogleLayer} from 'react-leaflet-google-v2';

import 'react-leaflet-markercluster/dist/styles.min.css';

import {hotelMarker, locationMarker, homeMarker} from './map3/HotelMarker';
import MarkerPopover from './map3/MarkerPopover';

import hotelSelector, {getAlternativeHotel} from 'airborne/search2/helpers/hotelSelector';
import {paginateHotels, destSingleHotel, isFiltered} from 'airborne/search2/helpers/hotels';

import {searchCoords} from 'airborne/store/modules/search_hotels/actions/search';
import {toList} from 'airborne/store/modules/search_hotels/actions';
import {toHotelRates} from 'airborne/store/modules/homepage/actions/redirect';
import {clearFilters} from '../store/modules/search_hotels/actions/filters';
import {mapCenter} from './map3/utils';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import QuerySize from 'airborne/search2/map3/QuerySize';

const DEFAULT_MAP_ZOOM = 13;
const MAX_RADIUS_METERS = 50000;

function shouldRenderAlternativeHotel(hotel) {
    if (!hotel || !Object.keys(hotel).length) return false;
    const {available: {'is_available': isAvailable, 'out_of_policy': outOfPolicy}={}, 'tmc_preferred': tmcPreferred} = hotel;
    return !isAvailable || !tmcPreferred || outOfPolicy;
}

const findCenter = createSelector(
    [
        hotelSelector,
        (state, {height})=> height,
        (state, {width})=> width,
    ],
    mapCenter,
);

const mapStateToProps = createSelector(
    [
        hotelSelector,
        findCenter,
        ({hotels: {filters}})=> filters.value,
        ({hotels: {locations}})=> locations.data,
        ({hotels: {hotels}})=> hotels.total,
        ({hotels: {hotels}})=> hotels.loading,
        ({hotels: {avail}})=> avail.loading,
        (state)=> getDestination(state).destination,
        (state)=> getAlternativeHotel(state),
    ],
    function (list, center, filters, locations, total, loading, avail, destination, altHotel) {
        const altHotelToMap = shouldRenderAlternativeHotel(list[0]) ? altHotel : null;
        const hotels = [...paginateHotels(list, filters), altHotelToMap].filter(identity);
        const {lat=null, lon=null, label} = destination;
        const home = (lat !== null && lon !== null)
            ? {latitude: lat, longitude: lon}
            : null;

        return {
            hotels,
            total,
            center,
            locations,
            home,
            homeLabel: label,
            ready: Boolean(home || center),
            loading: loading || !isEmpty(avail),
        };
    },
);


function PromptBar({onSearch, loading}) {

    const [center, setCenter] = useState(null);
    const [moved, setMoved] = useState(null);
    const map = useMapEvents({
        move(el) {
            setCenter(el.target.getCenter());
            setMoved(true);
        }
    });

    const getSearchRadius = () => {
        const bounds = map.getBounds();
        return Math.max(
            map.distance(bounds.getSouthWest(), bounds.getSouthEast()),
            map.distance(bounds.getSouthWest(), bounds.getNorthWest())
        ) / 2;
    };
    const handleSearch = () => {
        onSearch(center, getSearchRadius());
    };

    if (!moved) {
        return null;
    }

    if (getSearchRadius() > MAX_RADIUS_METERS) {
        return (<div className="map-view__prompt-bar">
            <div><strong>{gettext('You zoom out too far.')}</strong></div>
            <div>{gettext('Please zoom in to search this area.')}</div>
        </div>);
    }

    return (<div className="map-view__prompt-bar">
        <Button bsStyle="success"
            onClick={handleSearch}
            progress={loading}
            disabled={loading}>
            {gettext('Search this area »')}
        </Button>
    </div>);
}
PromptBar.propTypes = {
    loading: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
};

@connect(mapStateToProps, {onSelect: toHotelRates, onSearch: searchCoords})
class MapView extends React.Component {
    static propTypes = {
        hotels: PropTypes.array.isRequired,
        total: PropTypes.number.isRequired,
        locations: PropTypes.array.isRequired,
        ready: PropTypes.bool,
        loading: PropTypes.bool,
        center: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
            zoom: PropTypes.number,
        }),
        home: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }),
        homeLabel: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
    };

    state = {
        selected: null,
    };

    componentDidMount() {
        document.body.classList.add('modal-open');
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
    }

    handleClick = (event)=> {
        const {id} = event.target.options;
        this.setState({selected: id || null});
    };

    handleUnselect = ()=> {
        this.setState({selected: null});
    };

    renderSelected(hotels, selected) {
        const hotel = find(hotels, {id: selected});
        if (!hotel) {
            return null;
        }
        return (<MarkerPopover
            {...hotel}
            onClick={this.props.onSelect}
            onClose={this.handleUnselect} />);
    }

    renderMarkers(hotels) {
        return hotels.map((el)=> hotelMarker(el, this.handleClick));
    }

    renderMap() {
        const {selected} = this.state;
        const {home, homeLabel, hotels, locations, center={}, total} = this.props;
        if (!center) {
            this.props.onClose();
            return null;
        }
        const {latitude, longitude, zoom} = center;
        return (<div style={{width: '100%', height: '100%'}}>
            <MapContainer
                className="markercluster-map"
                center={[latitude, longitude]}
                zoom={zoom || DEFAULT_MAP_ZOOM}
                maxZoom={18}
            >
                <PromptBar {...this.props}/>

                <GoogleLayer googlekey={settings.GOOGLE_API_KEY} maptype="ROADMAP"/>
                {home && homeMarker(home, homeLabel)}
                {(hotels.length > 1) /* see https://github.com/YUzhva/react-leaflet-markercluster/issues/65 */
                    ? (<MarkerClusterGroup showCoverageOnHover={false}>
                        {this.renderMarkers(hotels)}
                    </MarkerClusterGroup>)
                    : this.renderMarkers(hotels)}
                {locations.map((el, idx) => locationMarker(el, idx))}
            </MapContainer>
            <div className="map-view__hotel-counter">
                {gettext('{num} of {count} hotels', {num: hotels.length, count: total})}
            </div>
            {selected && this.renderSelected(hotels, selected)}
        </div>);
    }

    renderLoading() {
        return (<div>{gettext('Loading')}</div>);
    }

    render() {
        const {ready} = this.props;
        return ready ? this.renderMap() : this.renderLoading();
    }
}

const mapFilterStateProps = createSelector([
    ({hotels})=> hotels.filters.value,
    destSingleHotel,
], (filters, searchSingleHotel)=> {
    return {
        hasFilters: isFiltered(filters, searchSingleHotel),
    };
});

@connect(mapFilterStateProps, {onClose: toList, onClear: clearFilters})
export default class HotelMap extends React.Component {
    static propTypes = {
        hasFilters: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
    };

    render() {
        const {hasFilters} = this.props;
        return (<div className="map-view__wrapper">
            <div className="map-view__control">
                <Button bsStyle="link" className="map-view__control-lnk" onClick={this.props.onClose}>
                    <span className="glyphicons glyphicons-remove-2" />
                    &nbsp;
                    {gettext('Close map')}
                </Button>
            </div>
            <div className="map-view__sidebar">
                {settings.SUB_PARTNER === 'aft' && (<MultiSearchContext />)}
                <div className="section-wrapper">
                    <div className="map-view__clear-filters">
                        {hasFilters && (<Button bsStyle="link" className="btn-link--narrow" onClick={this.props.onClear}>
                           &times; {gettext('Clear all filters')}
                        </Button>)}
                    </div>
                    <SearchFilters />
                </div>
            </div>
            <div className="map-view__map-wrapper">
                <QuerySize>
                    <MapView {...this.props} />
                </QuerySize>
            </div>
        </div>);
    }
}
