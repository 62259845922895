import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import Glyphicons from 'midoffice/components/Glyphicons';

import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import {getMinRate} from 'airborne/cars/search/map/helpers';


@connect((state, props)=> {
    const minRate = getMinRate(state, props);
    return {
        total: minRate.total,
        pictures: minRate.pictures || [],
    };
})
export default class MarkerPopover extends React.Component {
    static propTypes = {
        pickup: PropTypes.object,
        pictures: PropTypes.array,
        currency: PropTypes.string,
        vendor: PropTypes.object,
        name: PropTypes.string,
        id: PropTypes.string,
        vendorLocationCode: PropTypes.string,
        total: PropTypes.number,
        onClick: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    handleClick = () => {
        const {onClick, vendorLocationCode} = this.props;
        onClick({vendorLocationCode});
    }

    render() {
        const {
            pickup,
            currency,
            vendor,
            total,
        } = this.props;
        const {address, distance} = pickup;

        return (<div className="map-view__details" data-testid="cars-maps-marker-popover">
            <div className="map-view__details__close" onClick={this.props.onClose}>
                <Glyphicons bsClass="glyphicon" glyph="remove" />
            </div>

            <h4 className="map-view__details__name">
                <Button variant="link" onClick={this.handleClick}>{vendor.name}</Button>
            </h4>
            <div className="map-view__details__addr">
                {address?.streetAddress}, {address?.city}
            </div>

            <div className="map-view__details__info">
                <ul className="map-view__details__list list-unstyled">
                    <li>
                        <strong>{gettext('Starting from')} {total} {currency}</strong>
                    </li>
                    <li>
                        {gettext('Distance:')}
                        &nbsp;
                        {distance.value} {distance.units} {gettext('from city center')}
                    </li>
                </ul>
            </div>
            <div className="map-view__details__control">
                <Button variant="success" className="btn-block" onClick={this.handleClick}>
                    {gettext('See Rates')} &raquo;
                </Button>
            </div>
        </div>);
    }
}
