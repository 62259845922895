import React from 'react';
import PropTypes from 'prop-types';

export default class CarTooltip extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        address: PropTypes.string,
    };

    render() {
        const {name, address} = this.props;

        return (<div>
            <div className="map-view__tooltip__title" data-testid="cars-maps-marker-tooltip">
                {name}, {address}
            </div>
        </div>);
    }
}
