import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import {Marker, Tooltip} from 'react-leaflet';
import {divIcon} from 'leaflet';
import CarTooltip from 'airborne/cars/search/map/MarkerTooltip';

export default class CarMarker extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
        clickHandler: PropTypes.func.isRequired,
    }
    handleClick = () => {
        const {id, clickHandler} = this.props;
        clickHandler(id);
    }
    render () {
        const TOOLTIP_OFFSET = [-13, -50];
        const {id, latitude, longitude}  = this.props;
        return (
            <Marker
                id={id}
                eventHandlers={{
                    click: this.handleClick,
                }}
                key={id}
                position={{lat: latitude, lng: longitude}}
                icon={divIcon({
                    html: "<div data-testid='cars-map-rate-marker'></div>",
                    className: 'map-pin map-pin--car',
                    iconSize: [26, 32],
                    iconAnchor: [0, -15],
                })}>
                <Tooltip direction="top" offset={TOOLTIP_OFFSET}>
                    <CarTooltip {...this.props} />
                </Tooltip>
            </Marker>);
    }
}
